import {all, call, fork, put, takeLatest} from 'redux-saga/effects';
import axios from "axios";
import * as actionTypes from '../_actions/reportAction';
import { message } from 'antd';
import * as projectActionTypes from '../_actions/projectAction';

// Load Onco ///////////////////////////////////////////////////////////////////////////////////////////////////////////
function loadReportAPI(action) {
    return axios.get('/report/' + action.timepointID + '/' + action.readerIndex + '/' + action.reportType);
}
function infoAPI(data) {
    return axios.post('/user-info', data);
}
function* loadReport(action) {
    try {
        const result = yield call(() => loadReportAPI(action));
        const info = yield call(() => infoAPI(window.localStorage.getItem('cookie')));
        yield put({
            type: actionTypes.LOAD_REPORT_SUCCESS,
            payload: result.data,
            info: info.data,
            index: action.readerIndex,
        });
    } catch (err) {
        if(err.response.status === 404) {
            window.location.href = window.location.origin + '/NoWrittenReportPage';
        } else if(err.response.status === 409) {
            window.location.href = window.location.origin + '/timepointErrorPage';
        }
        yield put({
            type: actionTypes.LOAD_REPORT_FAILURE,
        })
    }
}
function* watchLoadReport() {
    yield takeLatest(actionTypes.LOAD_REPORT_REQUEST, loadReport);
}

// Load Report Info ////////////////////////////////////////////////////////////////////////////////////////////////////
function loadReportInfoAPI() {
    return axios.get('/report/code-item-list');
}
function* loadReportInfo() {
    try {
        const result = yield call(() => loadReportInfoAPI());
        yield put({
            type: actionTypes.LOAD_REPORT_INFO_SUCCESS,
            payload: result.data,
        });
    } catch (err) {
        if(err.response.status === 404) {
            window.location.href = window.location.origin + '/NoWrittenReportPage';
        }
        yield put({
            type: actionTypes.LOAD_REPORT_INFO_FAILURE,
        })
    }
}
function* watchLoadReportInfo() {
    yield takeLatest(actionTypes.LOAD_REPORT_INFO_REQUEST, loadReportInfo);
}

// Load Multi Report ///////////////////////////////////////////////////////////////////////////////////////////////////
function loadMultiReportAPI(action) {
    return axios.get('/report/multi/' + action.subjectID + '/' + action.reportType);
}
function* loadMultiReport(action) {
    try {
        const result = yield call(() => loadMultiReportAPI(action));
        const info = yield call(() => infoAPI(window.localStorage.getItem('cookie')));

        yield put({
            type: actionTypes.LOAD_MULTI_SUCCESS,
            payload: result.data,
            info: info.data,
        });
    } catch (err) {
        yield put({
            type: actionTypes.LOAD_MULTI_FAILURE,
        })
    }
}
function* watchLoadMultiReport() {
    yield takeLatest(actionTypes.LOAD_MULTI_REQUEST, loadMultiReport);
}

// Save Report /////////////////////////////////////////////////////////////////////////////////////////////////////////
function saveReportAPI(action) {
    if(action.data.sign === undefined) {
        return axios.put('/report/' + action.timepointID + '/' + action.readerIndex + '/' + action.reportType, action.data);
    } else {
        const config = {headers: {'TI-DS-ID': action.data.sign.id, 'TI-DS-PW': action.data.sign.password}};
        return axios.put('/report/' + action.timepointID + '/' + action.readerIndex + '/' + action.reportType, action.data, config);
    }
}
function loadSubjectAPI(data) {
    return axios.get('/subject/get-list/' + data);
}
function* saveReport(action) {
    try {
        const result = yield call(() => saveReportAPI(action));
        if(result.status === 200) {
            const subjectList = yield call(() => loadSubjectAPI(action.projectID));
            message.success({content: 'The report was saved successfully'}).then();
            yield put({
                type: projectActionTypes.LOAD_ONLY_SUBJECT_SUCCESS,
                payload: subjectList.data,
            });
            setTimeout(() => {
                window.location.reload();
            }, [1000]);
        }
        yield put({
            type: actionTypes.SAVE_REPORT_SUCCESS,
            payload: result.data.saveState,
        });
    } catch (err) {
        yield put({
            type: actionTypes.SAVE_REPORT_FAILURE,
        })
    }
}
function* watchSaveReport() {
    yield takeLatest(actionTypes.SAVE_REPORT_REQUEST, saveReport);
}

// Modify Recist ///////////////////////////////////////////////////////////////////////////////////////////////////////
function modifyReportAPI(action) {
    const config = {headers: {'TI-DS-ID': action.data.sign.id, 'TI-DS-PW': action.data.sign.password}};
    return axios.post('/report/modify/' + action.timepointID + '/' + action.readerIndex + '/' + action.reportType, action.data.data, config).then(function(response) {
        if(response.status === 200) {
            message.success({ content: 'The report was modified successfully' }).then();
            action.setVisible(false);
            setTimeout(() => {
                window.location.reload();
            }, [1000]);
        }
    });
}
function* modifyReport(action) {
    try {
        yield call(() => modifyReportAPI(action));
        yield put({
            type: actionTypes.MODIFY_REPORT_SUCCESS,
        });
    } catch (err) {
        yield put({
            type: actionTypes.MODIFY_REPORT_FAILURE,
        })
    }
}
function* watchModifyReport() {
    yield takeLatest(actionTypes.MODIFY_REPORT_REQUEST, modifyReport);
}

// Review Recist ///////////////////////////////////////////////////////////////////////////////////////////////////////
function reviewRecistAPI(data) {
    const config = {headers: {'TI-DS-ID': data.sign.id, 'TI-DS-PW': data.sign.password}};
    return axios.post('/report/re-review/' + data.data.subjectID + '/' + data.data.readerIdx + '/' + data.data.reportType, data.data, config).then(function(response) {
        if(response.status === 200) {
            message.success({ content: 'Re-Review Request' }).then();
            data.setVisible(false);
        } else {
            console.log(response)
        }
    });
}
function* reviewRecist(action) {
    try {
        yield call(() => reviewRecistAPI(action.data));
        yield put({
            type: actionTypes.REVIEW_RECIST_SUCCESS,
        });
    } catch (err) {
        yield put({
            type: actionTypes.REVIEW_RECIST_FAILURE,
        })
    }
}
function* watchReviewReport() {
    yield takeLatest(actionTypes.REVIEW_RECIST_REQUEST, reviewRecist);
}

// Load Report Audit ///////////////////////////////////////////////////////////////////////////////////////////////////
function loadReportAuditAPI(action) {
    return axios.get('/audit-report/' + action.timepointID + '/' + action.readerIndex + '/' + action.reportType);
}
function* loadReportAudit(action) {
    try {
        const result = yield call(() => loadReportAuditAPI(action));
        yield put({
            type: actionTypes.LOAD_REPORT_AUDIT_SUCCESS,
            payload: result.data,
        });
    } catch (err) {
        yield put({
            type: actionTypes.LOAD_REPORT_AUDIT_FAILURE,
        })
    }
}
function* watchLoadReportAudit() {
    yield takeLatest(actionTypes.LOAD_REPORT_AUDIT_REQUEST, loadReportAudit);
}

// Load Multi Audit ////////////////////////////////////////////////////////////////////////////////////////////////////
function loadMultiAuditAPI(action) {
    return axios.get('/audit-multiform-pick/' + action.subjectID);
}
function* loadMultiAudit(action) {
    try {
        const result = yield call(() => loadMultiAuditAPI(action));
        yield put({
            type: actionTypes.LOAD_MULTI_AUDIT_SUCCESS,
            payload: result.data,
        });
    } catch (err) {
        yield put({
            type: actionTypes.LOAD_MULTI_AUDIT_FAILURE,
        })
    }
}
function* watchLoadMultiAudit() {
    yield takeLatest(actionTypes.LOAD_MULTI_AUDIT_REQUEST, loadMultiAudit);
}

// Pick Report /////////////////////////////////////////////////////////////////////////////////////////////////////////
function pickReportAPI(data) {
    const config = {headers: {'TI-DS-ID': data.sign.id, 'TI-DS-PW': data.sign.password}};
    return axios.put('/adj/pick-report', {subjectID: data.subjectID, readerIdx: data.readerIdx, pickComment: data.comment}, config);
}
function* pickReport(action) {
    try {
        const result = yield call(() => pickReportAPI(action.data));
        if(result.status === 200) {
            message.success({ content: 'Pick Saved' }).then();
            action.data.setVisible(false);
        }
        yield put({
            type: actionTypes.PICK_REPORT_SUCCESS,
            payload: result.data,
            pick: action.data.readerIdx,
        });
    } catch (err) {
        yield put({
            type: actionTypes.PICK_REPORT_FAILURE,
        })
    }
}
function* watchPickReport() {
    yield takeLatest(actionTypes.PICK_REPORT_REQUEST, pickReport);
}

// Pick Report /////////////////////////////////////////////////////////////////////////////////////////////////////////
function getPickInfoAPI(data) {
    return axios.get('/adj/pick-report-idx/' + data);
}
function* getPickInfo(action) {
    try {
        const result = yield call(() => getPickInfoAPI(action.data));
        yield put({
            type: actionTypes.PICK_INFO_SUCCESS,
            payload: result.data,
        });
    } catch (err) {
        yield put({
            type: actionTypes.PICK_INFO_FAILURE,
        })
    }
}
function* watchGetPickInfo() {
    yield takeLatest(actionTypes.PICK_INFO_REQUEST, getPickInfo);
}

// Load Type ///////////////////////////////////////////////////////////////////////////////////////////////////////////
function loadTypeAPI() {
    return axios.get('/code-list/report-type');
}
function* loadType() {
    try {
        const result = yield call(() => loadTypeAPI());
        yield put({
            type: actionTypes.LOAD_TYPE_SUCCESS,
            payload: result.data,
        });
    } catch (err) {
        yield put({
            type: actionTypes.LOAD_TYPE_FAILURE,
        })
    }
}
function* watchLoadType() {
    yield takeLatest(actionTypes.LOAD_TYPE_REQUEST, loadType);
}

export default function* reportSaga() {
    yield all([
        fork(watchLoadReport),
        fork(watchLoadReportInfo),
        fork(watchLoadMultiReport),
        fork(watchSaveReport),
        fork(watchModifyReport),
        fork(watchReviewReport),
        fork(watchLoadReportAudit),
        fork(watchLoadMultiAudit),
        fork(watchPickReport),
        fork(watchGetPickInfo),
        fork(watchLoadType),
    ])
}