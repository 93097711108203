import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import axios from "axios";
import * as actionTypes from '../_actions/projectAction';
import { message } from 'antd';
import useMovePage from '../hooks/useMovePage';

// Create Project //////////////////////////////////////////////////////////////////////////////////////////////////////
function createProjectAPI(data) {
    return axios.post('/project', data);
}
function* createProject(action) {
    try {
        let project;
        const result = yield call(() => createProjectAPI(action.data));
        if(result.status === 200) {
            project = yield call(loadProjectAPI);
        }
        yield put({
            type: actionTypes.CREATE_PROJECT_SUCCESS,
            payload: project.data,
        });
    } catch (err) {
        yield put({
            type: actionTypes.CREATE_PROJECT_FAILURE,
        })
    }
}
function* watchCreateProject() {
    yield takeLatest(actionTypes.CREATE_PROJECT_REQUEST, createProject);
}

// Edit Project ////////////////////////////////////////////////////////////////////////////////////////////////////////
function editProjectAPI(data) {
    return axios.put('/project', data);
}
function* editProject(action) {
    try {
        const result = yield call(() => editProjectAPI(action.data));
        if(result.status === 200) {
            setTimeout(() => {
                action.setVisible !== null ? action.setVisible(false) : null;
            }, [1000])
        }
        yield put({
            type: actionTypes.EDIT_PROJECT_SUCCESS,
            payload: result.data,
        });
    } catch (err) {
        yield put({
            type: actionTypes.EDIT_PROJECT_FAILURE,
        })
    }
}
function* watchEditProject() {
    yield takeLatest(actionTypes.EDIT_PROJECT_REQUEST, editProject);
}

// Delete Project //////////////////////////////////////////////////////////////////////////////////////////////////////
function deleteProjectAPI(data) {
    return axios.delete('/project/' + data.id);
}
function* deleteProject(action) {
    try {
        yield call(() => deleteProjectAPI(action.data));
        yield put({
            type: actionTypes.DELETE_PROJECT_SUCCESS,
            payload: action.data,
        });
    } catch (err) {
        yield put({
            type: actionTypes.DELETE_PROJECT_FAILURE,
        })
    }
}
function* watchDeleteProject() {
    yield takeLatest(actionTypes.DELETE_PROJECT_REQUEST, deleteProject);
}

// Load Project ////////////////////////////////////////////////////////////////////////////////////////////////////////
function loadProjectAPI() {
    return axios.get('/project/get-list');
}
function infoAPI() {
    return axios.get('/user/get-info');
}
function* loadProject() {
    try {
        const result = yield call(loadProjectAPI);
        const info = yield call(infoAPI);
        if(window.location.pathname.includes('/list') && result.data.length === 0) {
            window.location.href = window.location.origin + '/main';
        }
        yield put({
            type: actionTypes.LOAD_PROJECT_SUCCESS,
            payload: result.data,
            info: info.data,
        });
    } catch (err) {
        yield put({
            type: actionTypes.LOAD_PROJECT_FAILURE,
        });
    }
}
function* watchLoadProject() {
    yield takeLatest(actionTypes.LOAD_PROJECT_REQUEST, loadProject);
}

// Get One Project /////////////////////////////////////////////////////////////////////////////////////////////////////
function* getOneProject(action) {
    try {
        const result = yield call(loadProjectAPI);
        yield put({
            type: actionTypes.GET_ONE_PROJECT_SUCCESS,
            projectID: action.projectID,
            payload: result.data,
        });
    } catch (err) {
        yield put({
            type: actionTypes.GET_ONE_PROJECT_FAILURE,
        });
    }
}
function* watchGetOneProject() {
    yield takeLatest(actionTypes.GET_ONE_PROJECT_REQUEST, getOneProject);
}

// Select Subject //////////////////////////////////////////////////////////////////////////////////////////////////////
function selectSubjectAPI(data) {
    return axios.get('/time-point/get-list-with-child/' + data);
}

function* selectSubject(action) {
    try {
        const result = yield call(() => selectSubjectAPI(action.data['subjectID'] === undefined ? action.data.id : action.data['subjectID']));
        yield put({
            type: actionTypes.SELECT_SUBJECT_SUCCESS,
            payload: result.data,
            result: action.data.name,
            onlyGetList: action.onlyGetList
        });
    } catch (err) {
        yield put({
            type: actionTypes.SELECT_SUBJECT_FAILURE,
        });
    }
}
function* watchSelectSubject() {
    yield takeLatest(actionTypes.SELECT_SUBJECT_REQUEST, selectSubject);
}

// Search Project //////////////////////////////////////////////////////////////////////////////////////////////////////
function* searchProject(action) {
    try {
        yield put({
            type: actionTypes.SEARCH_PROJECT_SUCCESS,
            payload: action.data,
        });
    } catch (err) {
        yield put({
            type: actionTypes.SEARCH_PROJECT_FAILURE,
        });
    }
}
function* watchSearchProject() {
    yield takeLatest(actionTypes.SEARCH_PROJECT_REQUEST, searchProject);
}

// Search Subject //////////////////////////////////////////////////////////////////////////////////////////////////////
function* searchSubject(action) {
    try {
        yield put({
            type: actionTypes.SEARCH_SUBJECT_SUCCESS,
            payload: action.data,
        });
    } catch (err) {
        yield put({
            type: actionTypes.SEARCH_SUBJECT_FAILURE,
        });
    }
}
function* watchSearchSubject() {
    yield takeLatest(actionTypes.SEARCH_SUBJECT_REQUEST, searchSubject);
}

// Search Scan /////////////////////////////////////////////////////////////////////////////////////////////////////////
function* searchScan(action) {
    try {
        yield put({
            type: actionTypes.SEARCH_SCAN_SUCCESS,
            payload: action.data,
        });
    } catch (err) {
        yield put({
            type: actionTypes.SEARCH_SCAN_FAILURE,
        });
    }
}
function* watchSearchScan() {
    yield takeLatest(actionTypes.SEARCH_SCAN_REQUEST, searchScan);
}

// Add My SubjectTable ////////////////////////////////////////////////////////////////////////////////////////////////////////
function editMyListAPI(data) {
    return axios.put('/user/bookmark/' + data);
}
function* editMyList(action) {
    try {
        const result = yield call(() => editMyListAPI(action.data));
        yield put({
            type: actionTypes.EDIT_MYLIST_SUCCESS,
            info: result.data,
        });
    } catch (err) {
        yield put({
            type: actionTypes.EDIT_MYLIST_FAILURE,
        })
    }
}
function* watchEditMyList() {
    yield takeLatest(actionTypes.EDIT_MYLIST_REQUEST, editMyList);
}

// Load Project Visit //////////////////////////////////////////////////////////////////////////////////////////////////
function loadProjectVisitAPI(data) {
    return axios.get('/time-point/get-all-in-project-with-child/' + data);
}
function* loadProjectVisit(action) {
    try {
        const result = yield call(() => loadProjectVisitAPI(action.data));
        if(result.status === 200) {
            action.setLoading(false);
        }
        yield put({
            type: actionTypes.LOAD_PROJECT_VISIT_SUCCESS,
            payload: result.data,
        });
    } catch (err) {
        yield put({
            type: actionTypes.LOAD_PROJECT_VISIT_FAILURE,
        });
    }
}
function* watchLoadProjectVisit() {
    yield takeLatest(actionTypes.LOAD_PROJECT_VISIT_REQUEST, loadProjectVisit);
}

// Load Subject ////////////////////////////////////////////////////////////////////////////////////////////////////////
function loadSubjectAPI(data) {
    return axios.get('/subject/get-list/' + data);
}
function* loadSubject(action) {
    try {
        const result = yield call(() => loadSubjectAPI(action.data));
        yield put({
            type: actionTypes.LOAD_SUBJECT_SUCCESS,
            payload: result.data,
        });
    } catch (err) {
        yield put({
            type: actionTypes.LOAD_SUBJECT_FAILURE,
        });
    }
}
function* watchLoadSubject() {
    yield takeLatest(actionTypes.LOAD_SUBJECT_REQUEST, loadSubject);
}

// Load Only Subject ///////////////////////////////////////////////////////////////////////////////////////////////////
function* loadOnlySubject(action) {
    try {
        const result = yield call(() => loadSubjectAPI(action.data));
        yield put({
            type: actionTypes.LOAD_ONLY_SUBJECT_SUCCESS,
            payload: result.data,
        });
    } catch (err) {
        yield put({
            type: actionTypes.LOAD_ONLY_SUBJECT_FAILURE,
        });
    }
}
function* watchLoadOnlySubject() {
    yield takeLatest(actionTypes.LOAD_ONLY_SUBJECT_REQUEST, loadOnlySubject);
}

// Load Only Baseline //////////////////////////////////////////////////////////////////////////////////////////////////
function* loadBaseline(action) {
    try {
        const result = yield call(() => selectSubjectAPI(action.data));
        yield put({
            type: actionTypes.LOAD_BASELINE_SUCCESS,
            payload: result.data,
        });
    } catch (err) {
        yield put({
            type: actionTypes.LOAD_BASELINE_FAILURE,
        });
    }
}
function* watchLoadBaseline() {
    yield takeLatest(actionTypes.LOAD_BASELINE_REQUEST, loadBaseline);
}

// Load Visit //////////////////////////////////////////////////////////////////////////////////////////////////////////
function loadVisitAPI() {
    return axios.get('/time-point/get-all-with-child');
}
function* loadVisit(action) {
    try {
        const result = yield call(loadVisitAPI);
        if(result.data.length >= 0) {
            action.setLoading(false);
        }
        yield put({
            type: actionTypes.LOAD_VISIT_SUCCESS,
            payload: result.data,
            setLoading: action.setLoading,
        });
    } catch (err) {
        yield put({
            type: actionTypes.LOAD_VISIT_FAILURE,
        });
    }
}
function* watchLoadVisit() {
    yield takeLatest(actionTypes.LOAD_VISIT_REQUEST, loadVisit);
}

// Load Overview ///////////////////////////////////////////////////////////////////////////////////////////////////////
function loadOverviewAPI(data) {
    return axios.get('/project/overview/' + data);
}
function* loadOverview(action) {
    try {
        const result = yield call(() => loadOverviewAPI(action.data));
        let siteData = [];
        if(result.data['siteInfo'].length > 0) {
            siteData = result.data['siteInfo'].map(function(v) {
                return [{site: v['institution'], type: 'Upload', value: v.upload}, {site: v['institution'], type: 'QC', value: v.qc}]
            }).reduce(function(acc, cur) {
                return acc.concat(cur);
            }).filter(function(v) {
                if(v.site !== null) return v
            })
        }
        yield put({
            type: actionTypes.LOAD_OVERVIEW_SUCCESS,
            siteData: siteData,
            qcPieData: Object.entries(result.data['qcState']).map(function(v) {
                return {status: v[0], value: v[1]}
            }),
            monitoringPieData: Object.entries(result.data['monitoringState']).map(function(v) {
                return {status: v[0], value: v[1]}
            }),
            queryPieData: Object.entries(result.data['queryState']).map(function(v) {
                return {status: v[0], value: v[1]}
            }),
            qcTableData: [result.data['qcState']],
            monitoringTableData: [result.data['monitoringState']],
            queryTableData: [result.data['queryState']],
            setIsFinished: action.setIsFinished,
        });
    } catch (err) {
        useMovePage(action.history, '/noProject', false);
        yield put({
            type: actionTypes.LOAD_OVERVIEW_FAILURE,
        });
    }
}
function* watchLoadOverview() {
    yield takeLatest(actionTypes.LOAD_OVERVIEW_REQUEST, loadOverview);
}

// Check Monitoring ////////////////////////////////////////////////////////////////////////////////////////////////////
function checkMonitoringAPI(action) {
    const config = {headers: {'TI-DS-ID': action.data.sign.id, 'TI-DS-PW': action.data.sign.password}};
   return axios.put('/time-point/monitoring-state', action.data.data, config);
}
function openQueryAPI(action) {
    const config = {headers: {'TI-DS-ID': action.data.sign.id, 'TI-DS-PW': action.data.sign.password}};
    return axios.post('/query/open', action.data.query, config);
}
function* checkMonitoring(action) {
    try {
        const response = yield call(() => checkMonitoringAPI(action));
        if(response.status === 200) {
            message.success({ content: 'Monitoring update complete'}).then();
            if(action.data.query.toUserEmail !== '' && action.data.query.toUserEmail !== undefined) {
                yield call(() => openQueryAPI(action));
            }
            setTimeout(() => {
                action.data.setIsSpinning(false)
                action.data.setVisible(false);
            }, [1000]);
        }
        yield put({
            type: actionTypes.CHECK_MONITORING_SUCCESS,
            payload: response.data,
        });
    } catch (err) {
        action.data.setIsSpinning(false);
        yield put({
            type: actionTypes.CHECK_MONITORING_FAILURE,
        });
    }
}
function* watchCheckMonitoring() {
    yield takeLatest(actionTypes.CHECK_MONITORING_REQUEST, checkMonitoring);
}

// Delete Sequence /////////////////////////////////////////////////////////////////////////////////////////////////////
function deleteSequenceAPI(data) {
    return axios.delete('/sequence/' + data);
}
function* deleteSequence(action) {
    try {
        yield call(() => deleteSequenceAPI(action.id));
        yield put({
            type: actionTypes.DELETE_SEQUENCE_SUCCESS,
        });
    } catch (err) {
        yield put({
            type: actionTypes.DELETE_SEQUENCE_FAILURE,
        })
    }
}
function* watchDeleteSequence() {
    yield takeLatest(actionTypes.DELETE_SEQUENCE_REQUEST, deleteSequence);
}

// Update Protocol /////////////////////////////////////////////////////////////////////////////////////////////////////
function* updateProtocol(action) {
    try {
        yield put({
            type: actionTypes.UPDATE_DOCUMENT_SUCCESS,
            payload: action,
        });
    } catch (err) {
        yield put({
            type: actionTypes.UPDATE_DOCUMENT_FAILURE,
        })
    }
}
function* watchUpdateProtocol() {
    yield takeLatest(actionTypes.UPDATE_DOCUMENT_REQUEST, updateProtocol);
}

// Update Protocol /////////////////////////////////////////////////////////////////////////////////////////////////////
function* updatePSQ(action) {
    try {
        yield put({
            type: actionTypes.UPDATE_PSQ_SUCCESS,
            payload: action,
        });
    } catch (err) {
        yield put({
            type: actionTypes.UPDATE_PSQ_FAILURE,
        })
    }
}
function* watchUpdatePSQ() {
    yield takeLatest(actionTypes.UPDATE_PSQ_REQUEST, updatePSQ);
}

// Clear Subject ///////////////////////////////////////////////////////////////////////////////////////////////////////
function* clearSubject() {
    try {
        yield put({
            type: actionTypes.CLEAR_SUBJECT_SUCCESS,
        });
    } catch (err) {
        yield put({
            type: actionTypes.CLEAR_SUBJECT_FAILURE,
        })
    }
}
function* watchClearSubject() {
    yield takeLatest(actionTypes.CLEAR_SUBJECT_REQUEST, clearSubject);
}

// Edit Subject ////////////////////////////////////////////////////////////////////////////////////////////////////////
function editSubjectAPI(action) {
    const config = {headers: {'TI-DS-ID': action.sign.id, 'TI-DS-PW': action.sign.password}};
    return axios.put('/subject', action.data, config);
}
function* editSubject(action) {
    try {
        const result = yield call(() => editSubjectAPI(action));
        if(result.status === 200) {
            action.setVisible(false);
            message.success({ content: 'Subject edit success'}).then();
        }
        yield put({
            type: actionTypes.EDIT_SUBJECT_SUCCESS,
            payload: result.data,
        });
    } catch (err) {
        message.error({ content: 'Subject edit fail'}).then();
        yield put({
            type: actionTypes.EDIT_SUBJECT_FAILURE,
        })
    }
}
function* watchEditSubject() {
    yield takeLatest(actionTypes.EDIT_SUBJECT_REQUEST, editSubject);
}

// Edit Visit //////////////////////////////////////////////////////////////////////////////////////////////////////////
function editVisitAPI(action) {
    const config = {headers: {'TI-DS-ID': action.sign.id, 'TI-DS-PW': action.sign.password}};
    return axios.put('/time-point/visit-type/' + action.data.id, action.data, config);
}
function* editVisit(action) {
    try {
        const result = yield call(() => editVisitAPI(action));
        if(result.status === 200) {
            action.setVisible(false);
            message.success({ content: 'Visit edit success'}).then();
        }
        yield put({
            type: actionTypes.EDIT_VISIT_SUCCESS,
            payload: result.data,
        });
    } catch (err) {
        message.error({ content: 'Visit edit fail'}).then();
        yield put({
            type: actionTypes.EDIT_VISIT_FAILURE,
        })
    }
}
function* watchEditVisit() {
    yield takeLatest(actionTypes.EDIT_VISIT_REQUEST, editVisit);
}

// Delete Visit ////////////////////////////////////////////////////////////////////////////////////////////////////////
function deleteVisitAPI(action) {
    console.log(action.sign.comment)
    const config = {headers: {'TI-DS-ID': action.sign.id, 'TI-DS-PW': action.sign.password}};
    return axios.delete('/time-point/all-scan/' + action.data.id, {data: {comment: action.sign.comment}}, config);
}
function* deleteVisit(action) {
    try {
        yield call(() => deleteVisitAPI(action));
        yield put({
            type: actionTypes.DELETE_IMAGE_SUCCESS,
            payload: action,
        });
    } catch (err) {
        message.error({ content: 'Image delete fail'}).then();
        yield put({
            type: actionTypes.DELETE_IMAGE_FAILURE,
        })
    }
}
function* watchDeleteVisit() {
    yield takeLatest(actionTypes.DELETE_IMAGE_REQUEST, deleteVisit);
}

// Download Audit //////////////////////////////////////////////////////////////////////////////////////////////////////
function downloadAuditAPI(action) {
    const config = {headers: {'TI-DS-ID': action.sign.id, 'TI-DS-PW': action.sign.password}, responseType: 'blob'};
    return axios.post('/export-' + action.field + '-audit/' + action.projectPK,null, config).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', '[' + action.projectName + '] ' + action.field.toUpperCase() + ' Audit Report_' + action.date + '.xlsx');
        document.body.appendChild(link);
        link.click();
        action.setSpinning(false);
        action.setOpen(false);
    });
}
function* downloadAudit(action) {
    try {
        yield call(() => downloadAuditAPI(action));
        yield put({
            type: actionTypes.DOWNLOAD_AUDIT_SUCCESS,
        });
    } catch (err) {
        message.error({ content: 'Download fail'}).then();
        yield put({
            type: actionTypes.DOWNLOAD_AUDIT_FAILURE,
        })
    }
}
function* watchDownloadAudit() {
    yield takeLatest(actionTypes.DOWNLOAD_AUDIT_REQUEST, downloadAudit);
}

function getMockDatasetAPI() {
    return axios.get('/mock/data-set');
}
function* getMockDataset() {
    try {
        const result = yield call(getMockDatasetAPI);
        yield put({
            type: actionTypes.GET_MOCK_DATASET_SUCCESS,
            payload: result.data,
        });
    } catch (err) {
        yield put({
            type: actionTypes.GET_MOCK_DATASET_FAILURE,
        })
    }
}
function* watchGetMockDataset() {
    yield takeLatest(actionTypes.GET_MOCK_DATASET_REQUEST, getMockDataset);
}

function getMockListAPI() {
    return axios.get('/mock/list');
}
function* getMockList() {
    try {
        const result = yield call(getMockListAPI);
        yield put({
            type: actionTypes.GET_MOCK_LIST_SUCCESS,
            payload: result.data,
        });
    } catch (err) {
        yield put({
            type: actionTypes.GET_MOCK_LIST_FAILURE,
        })
    }
}
function* watchGetMockList() {
    yield takeLatest(actionTypes.GET_MOCK_LIST_REQUEST, getMockList);
}

function setMockAPI(action) {
    return axios.post('/mock/' + action.url, action.data);
}
function* setMock(action) {
    try {
        const result = yield call(() => setMockAPI(action));
        if(result.status === 200) {
            setTimeout(() => {
                action.setVisible(false);
            }, [1000])
        }
        yield put({
            type: actionTypes.SET_MOCK_SUCCESS,
        });
    } catch (err) {
        yield put({
            type: actionTypes.SET_MOCK_FAILURE,
        })
    }
}
function* watchSetMock() {
    yield takeLatest(actionTypes.SET_MOCK_REQUEST, setMock);
}

function downMockAPI(action) {
    const config = {responseType: 'blob'};
    return axios.post('/mock/export-correlation/' + action.projectPK,null, config).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', action.protocolNum + '_correlation.xlsx');
        document.body.appendChild(link);
        link.click();
    });
}
function* downMock(action) {
    try {
        yield call(() => downMockAPI(action));
        yield put({
            type: actionTypes.DOWNLOAD_MOCK_SUCCESS,
        });
    } catch (err) {
        yield put({
            type: actionTypes.DOWNLOAD_MOCK_FAILURE,
        })
    }
}
function* watchDownMock() {
    yield takeLatest(actionTypes.DOWNLOAD_MOCK_REQUEST, downMock);
}

function getTodoAPI(action) {
    if(action.isAdjudicator === true)
        return axios.get('subject/to-do-list/' + action.projectPK);
    else
        return axios.get('/time-point/to-do-list/' + action.projectPK);
}
function* getTodo(action) {
    try {
        const result = yield call(() => getTodoAPI(action));
        if(result.status === 200) {
            action.setLoading(false);
        }
        yield put({
            type: actionTypes.GET_TODO_SUCCESS,
            payload: result.data,
            isAdjudicator: action.isAdjudicator,
        });
    } catch (err) {
        yield put({
            type: actionTypes.GET_TODO_FAILURE,
        });
    }
}
function* watchGetTodo() {
    yield takeLatest(actionTypes.GET_TODO_REQUEST, getTodo);
}

function addTodoAPI(action) {
    return axios.put('/time-point/to-do-list/' + action.projectPK + '/' + action.isMail, action.data);
}
function* addTodo(action) {
    try {
        const result = yield call(() => addTodoAPI(action));
        yield put({
            type: actionTypes.ADD_TODO_SUCCESS,
            payload: result.data,
        });
    } catch (err) {
        yield put({
            type: actionTypes.ADD_TODO_FAILURE,
        });
    }
}
function* watchAddTodo() {
    yield takeLatest(actionTypes.ADD_TODO_REQUEST, addTodo);
}

function exportTodoAPI(action) {
    const config = {headers: {'TI-DS-ID': action.sign.id, 'TI-DS-PW': action.sign.password}, responseType: 'blob'};
    return axios.post('/project/export-todolist/' + action.projectPK,null, config).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', action.protocolNum + '_TodoList.xlsx');
        document.body.appendChild(link);
        link.click();
        action.setSpinning(false);
        action.setOpen(false);
    });
}
function* exportTodo(action) {
    try {
        yield call(() => exportTodoAPI(action));
        yield put({
            type: actionTypes.EXPORT_TODO_SUCCESS,
        });
    } catch (err) {
        yield put({
            type: actionTypes.EXPORT_TODO_FAILURE,
        })
    }
}
function* watchExportTodo() {
    yield takeLatest(actionTypes.EXPORT_TODO_REQUEST, exportTodo);
}

// Edit Project ////////////////////////////////////////////////////////////////////////////////////////////////////////
function updateRoleAPI(action) {
    return axios.post('/project/update-authority/', action.data);
}
function* updateRole(action) {
    try {
        const result = yield call(() => updateRoleAPI(action));
        if(result.status === 200) {
            setTimeout(() => {
                action.setVisible !== null ? action.setVisible(false) : null;
            }, [1000])
        }
        yield put({
            type: actionTypes.UPDATE_ROLE_SUCCESS,
            payload: result.data,
        });
    } catch (err) {
        yield put({
            type: actionTypes.UPDATE_ROLE_FAILURE,
        })
    }
}
function* watchUpdateRole() {
    yield takeLatest(actionTypes.UPDATE_ROLE_REQUEST, updateRole);
}

export default function* projectSaga() {
    yield all([
        fork(watchCreateProject),
        fork(watchEditProject),
        fork(watchDeleteProject),
        fork(watchLoadProject),
        fork(watchGetOneProject),
        fork(watchSelectSubject),
        fork(watchLoadOnlySubject),
        fork(watchLoadBaseline),
        fork(watchSearchProject),
        fork(watchSearchSubject),
        fork(watchSearchScan),
        fork(watchEditMyList),
        fork(watchLoadProjectVisit),
        fork(watchLoadSubject),
        fork(watchLoadVisit),
        fork(watchLoadOverview),
        fork(watchCheckMonitoring),
        fork(watchDeleteSequence),
        fork(watchUpdateProtocol),
        fork(watchUpdatePSQ),
        fork(watchClearSubject),
        fork(watchEditSubject),
        fork(watchEditVisit),
        fork(watchDeleteVisit),
        fork(watchDownloadAudit),
        fork(watchGetMockDataset),
        fork(watchGetMockList),
        fork(watchSetMock),
        fork(watchDownMock),
        fork(watchGetTodo),
        fork(watchAddTodo),
        fork(watchExportTodo),
        fork(watchUpdateRole),
    ])
}